import * as XLSX from "xlsx";
import {ReturnData} from "../App";
import React from "react";
import Graph from "graphology";
import {Attributes} from "graphology-types";

export function exportHandler(returnData: ReturnData) {
    const workbook = XLSX.utils.book_new();
    const workSheetData: string[][] = [];
    const headerRow = [];

    const maxPath = Math.max(
        returnData.bfs?.pathSteps ?? 0,
        returnData.dfs?.pathSteps ?? 0,
        returnData.aStar?.pathSteps ?? 0,
        returnData.dijykstra?.pathSteps ?? 0
    );
    for(let i=0;i<maxPath;i++){

        const dataRow = []
        if (returnData.bfs) {
            if(i<=0){
                headerRow.push("Bfs Steps")
                headerRow.push("Bfs shortestPath Length")
                headerRow.push("Bfs shortest Path")
                dataRow.push(returnData.bfs.steps!.toString())
                dataRow.push(returnData.bfs.pathSteps!.toString())
            }else{
                dataRow.push("")
                dataRow.push("")
            }
            if(returnData.bfs.shortestPath?.length!>i){
                dataRow.push(returnData.bfs.shortestPath![i]!.toString());
            }else{
                dataRow.push("")
            }
        }

        if (returnData.dfs) {
            if(i<=0){
                headerRow.push("Dfs Steps")
                headerRow.push("Dfs shortestPath Length")
                headerRow.push("Dfs shortest Path")
                dataRow.push(returnData.dfs.steps!.toString())
                dataRow.push(returnData.dfs.pathSteps!.toString())
            }else{
                dataRow.push("")
                dataRow.push("")
            }
            if(returnData.dfs.shortestPath?.length!>i){
                dataRow.push(returnData.dfs.shortestPath![i]!.toString());
            }else{
                dataRow.push("")
            }
        }
        if (returnData.aStar) {
            if(i<=0){
                headerRow.push("A* Steps")
                headerRow.push("A* shortestPath Length")
                headerRow.push("A* shortest Path")
                dataRow.push(returnData.aStar.steps!.toString())
                dataRow.push(returnData.aStar.pathSteps!.toString())
            }else{
                dataRow.push("")
                dataRow.push("")
            }
            if(returnData.aStar.shortestPath?.length!>i){
                dataRow.push(returnData.aStar.shortestPath![i]!.toString());
            }else{
                dataRow.push("")
            }
        }
        if (returnData.dijykstra) {
            if(i<=0){
                headerRow.push("Dijkstra Steps")
                headerRow.push("Dijkstra shortestPath Length")
                headerRow.push("Dijkstra shortest Path")
                dataRow.push(returnData.dijykstra.steps!.toString())
                dataRow.push(returnData.dijykstra.pathSteps!.toString())
            }else{
                dataRow.push("")
                dataRow.push("")
            }
            if(returnData.dijykstra.shortestPath?.length!>i){
                dataRow.push(returnData.dijykstra.shortestPath![i]!.toString());
            }else{
                dataRow.push("")
            }
        }
        if(i===0){
            workSheetData.push(headerRow);
        }
        workSheetData.push(dataRow)
    }

    const worksheet = XLSX.utils.aoa_to_sheet(workSheetData);

    XLSX.utils.book_append_sheet(workbook, worksheet, "sheet1");
    XLSX.writeFile(workbook, "data.xlsx")
}

export function haversineDistance(coord1: any, coord2: any) {
    const toRadians = (degree: number) => {
        return (degree * Math.PI) / 180;
    };
    const R = 6371;

    const dLat = toRadians(coord2.latitude - coord1.latitude);
    const dLon = toRadians(coord2.longitude - coord1.longitude);

    const lat1 = toRadians(coord1.latitude);
    const lat2 = toRadians(coord2.latitude);

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
}

export function heuristicCalc(graphRef: React.MutableRefObject<Graph<Attributes, Attributes, Attributes>>, nodeB: any, nodeA: any) {
    let d1 = Math.abs(graphRef.current.getNodeAttribute(nodeB, "x") - graphRef.current.getNodeAttribute(nodeA, "x"));
    let d2 = Math.abs(graphRef.current.getNodeAttribute(nodeB, "y") - graphRef.current.getNodeAttribute(nodeA, "y"));

    return d1 + d2;
}

export function readFileData(reader: FileReader, file: any, setData: (value: any) => void) {
    reader.readAsBinaryString(file);
    reader.onload = (e) => {
        const data = e.target?.result;
        const workbook = XLSX.read(data, {type: "string"});
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);
        setData(parsedData);
    };
}

